<template>
   <v-dialog v-model="dialog" max-width="800px">
      <Notification type="error" message="Error" ref="error_noti" />
      <v-card :loading="isloading">
         <v-toolbar elevation="2" :color="isAbono ? 'success' : 'error'">{{ title }}</v-toolbar>
         <v-card-text>
            <form>
               <v-row>
                  <v-slide-x-reverse-transition>
                     <v-col v-if="fondos" cols="3">
                        <v-text-field
                           :value="show_fondos ? fondos : '00.00'"
                           label="Fondos"
                           readonly
                           prepend-inner-icon="mdi-currency-usd"
                           :type="show_fondos ? 'text' : 'password'"
                           :append-icon="show_fondos ? 'mdi-eye' : 'mdi-eye-off'"
                           @click:append="show_fondos = !show_fondos"
                        ></v-text-field>
                     </v-col>
                  </v-slide-x-reverse-transition>
                  <v-col>
                     <v-text-field
                        v-model="cuenta"
                        label="Cuenta"
                        type="number"
                        :error-messages="cuentaErrors"
                        required
                        class="inputnum"
                        @input="$v.cuenta.$touch()"
                        @blur="$v.cuenta.$touch()"
                     ></v-text-field>
                  </v-col>
                  <v-col v-if="!isAbono" cols="1" class="d-flex align-center justify-center">--</v-col>
                  <v-col v-if="!isAbono" cols="2">
                     <v-text-field
                        v-model="subcuenta"
                        label="Subcuenta"
                        class="inputnum"
                        type="number"
                        :error-messages="subcuentaErrors"
                        required
                        clearable
                        @input="$v.subcuenta.$touch()"
                        @blur="$v.subcuenta.$touch()"
                     ></v-text-field>
                  </v-col>
                  <v-slide-x-reverse-transition>
                     <v-col v-if="!isAbono && maximo" cols="2">
                        <v-text-field v-model="maximo" label="Máximo" readonly prepend-inner-icon="mdi-currency-usd"></v-text-field>
                     </v-col>
                  </v-slide-x-reverse-transition>
               </v-row>
               <v-row>
                  <v-col>
                     <v-text-field
                        v-model="importe"
                        label="Importe"
                        type="number"
                        class="inputnum"
                        :error-messages="importeErrors"
                        prepend-icon="mdi-currency-usd"
                        required
                        @input="$v.importe.$touch()"
                        @blur="$v.importe.$touch()"
                     ></v-text-field>
                  </v-col>
                  <v-slide-x-reverse-transition>
                     <v-col cols="2" v-if="comision">
                        <v-text-field v-model="comision" label="Comisión" readonly prepend-inner-icon="mdi-currency-usd"></v-text-field>
                     </v-col>
                  </v-slide-x-reverse-transition>
                  <v-col v-if="!isAbono">
                     <v-text-field
                        v-model="nip"
                        label="NIP"
                        :error-messages="nipErrors"
                        :type="show_nip ? 'text' : 'password'"
                        :append-icon="show_nip ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="show_nip = !show_nip"
                        @input="$v.nip.$touch()"
                        @blur="
                           $v.nip.$touch();
                           getComision();
                        "
                     ></v-text-field>
                  </v-col>
               </v-row>
               <v-row>
                  <v-col>
                     <v-text-field
                        v-model="referencia"
                        label="Referencia"
                        :error-messages="referenciaErrors"
                        @input="$v.referencia.$touch()"
                        @blur="$v.referencia.$touch()"
                     ></v-text-field>
                  </v-col>
               </v-row>
               <v-row>
                  <v-col v-if="isAbono">
                     <v-text-field
                        v-if="importe && comision"
                        label="Cobrar"
                        readonly
                        :messages="['Total a cobrar (importe + comisión)']"
                        :value="currency_format(+importe + +comision)"
                     ></v-text-field>
                  </v-col>
                  <v-col v-else>
                     <v-text-field
                        v-if="importe && comision"
                        label="Entregar"
                        readonly
                        :messages="[`Cargo a cuenta ${currency_format(+importe + +comision)} importe + comisión`]"
                        :value="currency_format(importe)"
                     ></v-text-field>
                  </v-col>
               </v-row>
            </form>
         </v-card-text>
         <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="close">Cancelar</v-btn>
            <v-btn :loading="isloading" @click="save" :color="isAbono ? 'success' : 'error'">Aceptar</v-btn>
         </v-card-actions>
      </v-card>
   </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, integer, maxLength } from "vuelidate/lib/validators";
import { printTicket } from "../../modules/printTicket";

export default {
   mixins: [validationMixin],
   validations: {
      referencia: {
         required,
         maxLength: maxLength(255),
      },
      importe: {
         required,
         number: function () {
            return !isNaN(this.importe);
         },
         positivo: function () {
            return this.importe > 0;
         },
         maximo_importe: function () {
            if (this.maximo && !this.isAbono) {
               if (+this.maximo == 0) {
                  return true;
               }
               return +this.maximo >= +this.importe;
            }
            return true;
         },
         maximo_importe_fondos: function () {
            if (this.fondos && !this.isAbono) {
               return +this.fondos >= +this.importe;
            }
            return true;
         },
      },
      cuenta: {
         required,
         integer,
         size: function () {
            if (this.cuenta) {
               return this.cuenta.length == 8;
            }
            return true;
         },
         positivo: function () {
            return this.cuenta > 0;
         },
      },
      nip: {
         required: function () {
            if (this.isAbono) {
               return true;
            } else {
               if (this.nip) {
                  return this.nip.length > 0;
               }
               return false;
            }
         },
         integer: function () {
            if (this.isAbono) {
               return true;
            } else {
               return Number.isInteger(+this.nip);
            }
         },
         size: function () {
            if (this.nip) {
               return this.nip.length == 6;
            }
            return true;
         },
         positivo: function () {
            if (this.isAbono) {
               return true;
            } else {
               return this.nip > 0;
            }
         },
      },
      subcuenta: {
         integer,
         maxLength: maxLength(4),
         positivo: function () {
            if (this.subcuenta) {
               return this.subcuenta > 0;
            }
            return true;
         },
      },
   },
   data: function () {
      return {
         cuenta: null,
         subcuenta: null,
         importe: null,
         comision: null,
         fondos: null,
         maximo: null,
         nip: null,
         referencia: null,
         isAbono: false,
         dialog: false,
         isloading: false,
         show_nip: false,
         show_fondos: false,
      };
   },
   watch: {
      dialog: function (val) {
         if (!val) {
            this.close();
            this.$emit("close");
         }
      },
      importe: function () {
         this.getComision();
      },
      cuenta: function () {
         this.getFondosAndMaximos();
      },
      subcuenta: function () {
         if (this.$store.state.fondos_maximos.fondo) {
            if (this.$store.state.fondos_maximos.maximos) {
               let maximos = this.$store.state.fondos_maximos.maximos;
               let maximo = maximos.find((elem) => elem.consecutivo == this.subcuenta);
               if (maximo) {
                  this.maximo = maximo.max;
               } else {
                  this.maximo = null;
               }
            }
         }
      },
   },
   methods: {
      save: async function () {
         try {
            this.$v.$touch();
            if (!this.$v.$invalid) {
               this.isloading = true;
               let res = null;
               if (this.isAbono) {
                  res = await this.$store.dispatch("abonoCuenta", {
                     cuenta: this.cuenta,
                     importe: this.importe,
                     referencia: this.referencia,
                     nip: this.nip,
                  });
               } else {
                  if (this.subcuenta) {
                     res = await this.$store.dispatch("cargoSubcuenta", {
                        cuenta: this.cuenta,
                        consecutivo: this.subcuenta,
                        importe: this.importe,
                        referencia: this.referencia,
                        nip: this.nip,
                     });
                  } else {
                     res = await this.$store.dispatch("cargoCuenta", {
                        cuenta: this.cuenta,
                        importe: this.importe,
                        referencia: this.referencia,
                        nip: this.nip,
                     });
                  }
               }
               if (!(res instanceof Error)) {
                  this.$emit("save");
                  this.close();
                  printTicket(res.data.data.movimiento[0]);
               } else {
                  throw res;
               }
            } else {
               throw "error";
            }
         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         } finally {
            this.isloading = false;
         }
      },
      getFondosAndMaximos: async function () {
         try {
            if (this.cuenta) {
               if (this.cuenta.length == 8) {
                  if (!isNaN(this.cuenta)) {
                     if (this.cuenta > 0) {
                        await this.$store.dispatch("getFondosMaximos", {
                           cuenta: this.cuenta,
                        });
                        if (this.$store.state.fondos_maximos.fondo) {
                           this.fondos = this.$store.state.fondos_maximos.fondo;
                        } else {
                           this.fondos = null;
                        }
                        return;
                     }
                  }
               }
            }
            this.fondos = null;
            return;
         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         } finally {
            this.isloading = false;
         }
      },
      getComision: async function () {
         try {
            if (this.importe) {
               if (!isNaN(this.importe)) {
                  if (this.importe > 0) {
                     if (this.isAbono) {
                        await this.$store.dispatch("getComisionAbonos", {
                           importe: this.importe,
                        });
                     } else {
                        await this.$store.dispatch("getComisionCargos", {
                           importe: this.importe,
                        });
                     }
                     if (this.$store.state.comision) {
                        if (this.$store.state.comision.importe) {
                           this.comision = this.$store.state.comision.importe;
                        } else {
                           this.comision = null;
                        }
                     } else {
                        this.comision = null;
                     }
                     return;
                  }
               }
            }
            this.comision = null;
            return;
         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         } finally {
            this.isloading = false;
         }
      },
      close: function () {
         this.dialog = false;
         this.$v.$reset();
      },
      Show: function (type) {
         this.dialog = true;
         this.isAbono = type;
         this.cuenta = null;
         this.subcuenta = null;
         this.importe = null;
         this.comision = null;
         this.fondos = null;
         this.maximo = null;
         this.nip = null;
         this.referencia = null;
      },
      currency_format(num) {
         var formatter = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
         });
         return formatter.format(num);
      },
   },
   computed: {
      title() {
         if (this.isAbono) {
            return "Abono a Cuenta";
         } else {
            if (this.subcuenta) {
               return "Cargo a Subcuenta";
            } else {
               return "Cargo a Cuenta";
            }
         }
      },
      referenciaErrors() {
         const errors = [];
         if (!this.$v.referencia.$dirty) return errors;
         !this.$v.referencia.required && errors.push("Es requerido");
         !this.$v.referencia.maxLength && errors.push("Máximo 255 caracteres");
         return errors;
      },
      cuentaErrors() {
         const errors = [];
         if (!this.$v.cuenta.$dirty) return errors;
         !this.$v.cuenta.required && errors.push("Es requerido");
         !this.$v.cuenta.size && errors.push("Debe contener 8 caracteres");
         !this.$v.cuenta.positivo && errors.push("Debe ser un numero positivo");
         !this.$v.cuenta.integer && errors.push("Indique un numero entero");
         return errors;
      },
      nipErrors() {
         const errors = [];
         if (!this.$v.nip.$dirty) return errors;
         !this.$v.nip.required && errors.push("Es requerido");
         !this.$v.nip.size && errors.push("Debe contener 6 caracteres");
         !this.$v.nip.positivo && errors.push("Debe ser un numero positivo");
         !this.$v.nip.integer && errors.push("Indique un numero entero");
         return errors;
      },
      importeErrors() {
         const errors = [];
         if (!this.$v.importe.$dirty) return errors;
         !this.$v.importe.required && errors.push("Es requerido");
         !this.$v.importe.number && errors.push("Indique una cantidad");
         !this.$v.importe.positivo && errors.push("Debe ser un numero positivo");
         !this.$v.importe.maximo_importe && errors.push("Importe máximo superado");
         !this.$v.importe.maximo_importe_fondos && errors.push("Fondos insuficientes");
         return errors;
      },
      subcuentaErrors() {
         const errors = [];
         if (!this.$v.subcuenta.$dirty) return errors;
         !this.$v.subcuenta.maxLength && errors.push("Debe contener máximo 4 caracteres");
         !this.$v.subcuenta.positivo && errors.push("Debe ser un numero positivo");
         !this.$v.subcuenta.integer && errors.push("Indique un numero entero");
         return errors;
      },
   },
};
</script>

<style scoped>
.v-text-field >>> input {
   font-size: 25px;
}
.inputnum >>> input[type="number"] {
   -moz-appearance: textfield;
}
.inputnum >>> input::-webkit-outer-spin-button,
.inputnum >>> input::-webkit-inner-spin-button {
   appearance: none;
   -webkit-appearance: none;
   -moz-appearance: none;
}
</style>
