<template>
   <div>
      <Dashboard />
   </div>
</template>

<script>
import Dashboard from "../components/Dashboard/Dashboard.vue";

export default {
   data: () => {
      return {};
   },
   components: {
      Dashboard,
   },
};
</script>

<style></style>
